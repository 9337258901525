/* COLORS */
/* ---------------------------- */
/* COLORS */
/* ---------------------------- */
.process-attribute-field {
  display: flex;
  line-height: 20px; }
  .process-attribute-field:first-child {
    margin-right: 16px; }
  .process-attribute-field > div {
    max-width: 280px; }
  .process-attribute-field input {
    margin-right: 16px;
    max-width: 280px;
    flex: 1; }
  .process-attribute-field > span, .process-attribute-field > div {
    flex: 1;
    display: block;
    color: rgba(34, 34, 34, 0.48);
    white-space: nowrap;
    min-width: 0;
    text-overflow: ellipsis;
    margin-right: 8px; }
    .process-attribute-field > span:last-child, .process-attribute-field > div:last-child {
      flex: 2;
      color: rgba(0, 0, 0, 0.88);
      margin-right: 0px; }
  .process-attribute-field .edit {
    padding: 5px 0; }

.process-attribute-datatype {
  display: flex;
  vertical-align: top;
  align-items: middle;
  justify-content: middle; }
  .process-attribute-datatype img {
    vertical-align: top;
    opacity: 0.5;
    margin-right: 4px; }
  .process-attribute-datatype span {
    vertical-align: top;
    line-height: 20px; }

.process-attribute-select {
  z-index: 4; }

.process-attribute {
  display: flex; }
  .process-attribute div, .process-attribute .process-attr-name {
    flex: 1; }
  .process-attribute .process-attr-name {
    color: rgba(0, 0, 0, 0.68); }
  .process-attribute .recurrent-label {
    flex: 1;
    color: rgba(0, 0, 0, 0.28); }
    .process-attribute .recurrent-label.hidden {
      visibility: hidden; }

.process-attr-selected, .process-attribute-new {
  display: flex;
  flex-direction: column;
  margin-right: 32px; }
  .process-attr-selected > div.process-attr-inputs, .process-attribute-new > div.process-attr-inputs {
    display: flex;
    flex-direction: row; }
    .process-attr-selected > div.process-attr-inputs > div, .process-attribute-new > div.process-attr-inputs > div {
      flex: 1; }
  .process-attr-selected .recurrent-checkbox, .process-attribute-new .recurrent-checkbox {
    padding-bottom: 16px; }
  .process-attr-selected .error, .process-attribute-new .error {
    margin-top: 4px;
    color: #dd5151; }
