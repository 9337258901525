/* COLORS */
/* ---------------------------- */
.checklist-item {
  background-color: white;
  width: 600px;
  max-width: 600px;
  border-radius: 4px;
  margin: 16px;
  box-shadow: rgba(0, 0, 0, 0.08) 0 0 6px;
  display: flex;
  overflow: hidden;
  cursor: pointer; }
  .checklist-item .checklist-item-circle {
    margin: 16px 0 16px 16px;
    min-width: 32px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .checklist-item .checklist-item-circle div {
      min-width: 24px;
      width: 24px;
      height: 24px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center; }
  .checklist-item .checklist-item-content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    color: rgba(0, 0, 0, 0.68);
    line-height: 22px; }
    .checklist-item .checklist-item-content .checklist-item-header {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.88); }
  .checklist-item .checklist-item-link {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0073f9;
    color: white; }
  .checklist-item.checklist-item-done .checklist-item-circle div {
    border: 0px; }
    .checklist-item.checklist-item-done .checklist-item-circle div i {
      font-size: 26px;
      color: #B4EF67; }
  .checklist-item.checklist-item-done span.checklist-item-header {
    color: rgba(0, 0, 0, 0.48); }
  .checklist-item.checklist-item-done span.checklist-item-text {
    color: rgba(0, 0, 0, 0.28); }
  .checklist-item.checklist-item-done .checklist-item-link {
    background-color: rgba(0, 115, 249, 0.5); }
