/* COLORS */
/* ---------------------------- */
.objectlist-zerostate {
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%; }
  .objectlist-zerostate > span, .objectlist-zerostate > a {
    text-align: center;
    margin-bottom: 16px; }
  .objectlist-zerostate i {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 4px; }
  .objectlist-zerostate a {
    color: #0073f9; }
  .objectlist-zerostate .objectlist-zerostate-header {
    font-size: 16px; }
  .objectlist-zerostate .create-btn, .objectlist-zerostate .objectlist-zerostate-header {
    font-weight: 600; }
