.specific-page-new-features-card {
  min-width: 500px;
  max-width: 800px; }
  .specific-page-new-features-card .new-feature {
    padding: 24px; }
  .specific-page-new-features-card .new-feature-text {
    padding-top: 12px;
    padding-bottom: 24px; }
  .specific-page-new-features-card .new-feature-header {
    font-weight: bold;
    padding-bottom: 24px;
    font-size: 16px; }
  .specific-page-new-features-card .learn-how {
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold; }
