/* COLORS */
/* ---------------------------- */
.task-page {
  height: calc(100vh - 64px); }
  .task-page .task-container {
    display: flex;
    min-height: calc(100% - 56px);
    min-width: calc(100vw - 64px);
    max-width: 1200px;
    background-color: white;
    padding-right: 40px; }
