/* COLORS */
/* ---------------------------- */
.product-page {
  width: 100%;
  background-color: white; }
  .product-page .product-page-recipe-list {
    flex: 11;
    background-color: white; }
  .product-page .product-information {
    flex: 5; }

.product-information {
  padding: 0 36px;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  flex: 1; }
  .product-information .product-basic-information-container {
    flex: 1; }
  .product-information .product-information-basic .piece-of-info {
    display: flex;
    flex-direction: column;
    padding: 12px 0; }
    .product-information .product-information-basic .piece-of-info > span:last-child {
      margin-top: 8px; }
    .product-information .product-information-basic .piece-of-info .no-description {
      color: rgba(0, 0, 0, 0.28); }
    .product-information .product-information-basic .piece-of-info .no-tags {
      color: rgba(0, 0, 0, 0.28); }
    .product-information .product-information-basic .piece-of-info .emphasis {
      font-weight: 700; }
