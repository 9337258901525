/* COLORS */
/* ---------------------------- */
.inventory-drawer {
  width: 368px;
  background-color: white;
  border-left: 1px solid rgba(0, 0, 0, 0.08); }
  .inventory-drawer .bold {
    font-weight: bold; }
  .inventory-drawer .inv-drawer-title {
    padding: 16px 0 12px 16px;
    display: flex;
    align-items: center;
    line-height: 28px;
    font-size: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    position: relative; }
    .inventory-drawer .inv-drawer-title .cancel {
      position: absolute;
      right: 20px;
      bottom: 8px;
      line-height: 14px;
      font-size: 14px;
      color: #0073f9;
      cursor: pointer; }
  .inventory-drawer .inv-icon-circ {
    align-self: flex-start;
    margin-top: -4px;
    margin-right: 12px; }
  .inventory-drawer .add-adjustment {
    height: 28px;
    width: 100%;
    color: white;
    font-size: 12px;
    background-color: #0073f9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .inventory-drawer .inv-adjuster {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08); }
    .inventory-drawer .inv-adjuster .form-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px; }
    .inventory-drawer .inv-adjuster .question {
      width: 168px; }
    .inventory-drawer .inv-adjuster .form {
      width: 140px;
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
    .inventory-drawer .inv-adjuster .input-container {
      position: relative; }
    .inventory-drawer .inv-adjuster .input {
      padding: 0 8px;
      height: 28px;
      line-height: 28px; }
    .inventory-drawer .inv-adjuster .input, .inventory-drawer .inv-adjuster .textarea {
      border-color: rgba(0, 0, 0, 0.08); }
    .inventory-drawer .inv-adjuster .explanation-title {
      margin-bottom: 4px;
      font-weight: bold; }
    .inventory-drawer .inv-adjuster .textarea {
      height: 124px; }
    .inventory-drawer .inv-adjuster input[type="number"]::-webkit-outer-spin-button, .inventory-drawer .inv-adjuster input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .inventory-drawer .inv-adjuster .unit-label {
      line-height: 28px;
      position: absolute;
      right: 8px;
      color: rgba(0, 0, 0, 0.28); }
    .inventory-drawer .inv-adjuster .discrepancy {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.48);
      margin-bottom: 12px;
      min-height: 20px; }
    .inventory-drawer .inv-adjuster button {
      margin-top: 16px;
      padding: 0 16px;
      height: 24px;
      align-self: flex-end; }

.history-item {
  padding: 24px 24px 12px;
  line-height: 20px; }
  .history-item .description {
    margin-bottom: 4px; }
  .history-item .explanation {
    margin: 8px 0;
    font-style: italic; }
  .history-item .adjustment-pill {
    display: inline-block;
    background-color: #fea300;
    height: 16px;
    line-height: 16px;
    padding: 0 8px;
    font-size: 12px;
    color: white;
    border-radius: 100px;
    margin-right: 8px; }
  .history-item .formatted-date {
    color: rgba(0, 0, 0, 0.28); }
  .history-item .date-range {
    color: rgba(0, 0, 0, 0.28); }
