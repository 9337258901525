/* COLORS */
/* ---------------------------- */
.delivery-history-filters {
  padding: 32px;
  color: rgba(0, 0, 0, 0.48); }
  .delivery-history-filters .row {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
  .delivery-history-filters .datepicker {
    width: 200px;
    margin-right: 20px; }
  .delivery-history-filters .select-container {
    width: 300px;
    margin-right: 20px;
    position: relative; }
    .delivery-history-filters .select-container > i {
      font-size: 20px;
      position: absolute;
      top: 9px;
      left: 8px; }
    .delivery-history-filters .select-container .ant-select {
      width: 100%; }
    .delivery-history-filters .select-container .Select-control {
      background-color: transparent;
      padding-left: 23px; }
    .delivery-history-filters .select-container .Select--multi .Select-input {
      margin-left: 5px; }
    .delivery-history-filters .select-container .Select-placeholder {
      padding-left: 28px; }
  .delivery-history-filters .checkboxes {
    display: flex;
    flex-grow: 1; }
