/* COLORS */
/* ---------------------------- */
.pill {
  display: inline-block;
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  color: white;
  margin-right: 4px; }
  .pill.purple {
    background-color: #5827d3; }
  .pill.gray {
    background-color: rgba(0, 0, 0, 0.08);
    color: rgba(0, 0, 0, 0.48); }
  .pill.turquoise {
    background-color: #1ddbb1; }
