/* COLORS */
/* ---------------------------- */
.process-page {
  width: 100%; }
  .process-page .process-information {
    flex: 3; }
  .process-page .process-attributes {
    flex: 5; }
  .process-page .help-container {
    z-index: 1000;
    position: fixed;
    bottom: 30px;
    right: 0px;
    margin-right: 24px;
    margin-bottom: 76px; }
  .process-page .help {
    background-color: #0073f9;
    margin-bottom: 24px;
    color: white;
    padding: 12px;
    border-radius: 8px;
    width: 400px;
    cursor: pointer; }
  .process-page .help-header {
    font-weight: bold;
    padding-bottom: 12px; }
  .process-page .help-link {
    font-weight: bold; }

.process-information {
  padding: 0 36px;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  flex: 1; }
  .process-information .process-basic-information-container {
    padding-bottom: 100px; }
  .process-information .process-information-basic .piece-of-info {
    display: flex;
    flex-direction: column;
    padding: 12px 0; }
    .process-information .process-information-basic .piece-of-info > span:last-child {
      margin-top: 8px; }
    .process-information .process-information-basic .piece-of-info .no-description {
      color: rgba(0, 0, 0, 0.28); }
    .process-information .process-information-basic .piece-of-info .no-tags {
      color: rgba(0, 0, 0, 0.28); }
    .process-information .process-information-basic .piece-of-info .emphasis {
      font-weight: 700; }

.process-attributes {
  background-color: white;
  padding-bottom: 400px; }
