/* COLORS */
/* ---------------------------- */
.task-dialog {
  background-color: white;
  width: 380px;
  border-radius: 4px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.09); }
  .task-dialog .body {
    padding-left: 16px;
    height: 460px;
    overflow: auto; }
  .task-dialog .task {
    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    display: flex; }
  .task-dialog .task-icon {
    height: 40px;
    width: 40px; }
  .task-dialog .info {
    margin-left: 12px; }
  .task-dialog .code {
    color: rgba(0, 0, 0, 0.88); }
  .task-dialog .name {
    color: rgba(0, 0, 0, 0.48); }
