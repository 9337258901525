/* COLORS */
/* ---------------------------- */
.goals-side-bar {
  min-width: 260px;
  max-width: 260px;
  background-color: white; }
  .goals-side-bar .goal-side-bar-tabs {
    padding-top: 38px;
    padding-left: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08); }
  .goals-side-bar .goals-list-wrapper {
    flex: 1;
    padding-right: 24px;
    padding-left: 10px;
    padding-bottom: 200px;
    overflow: hidden;
    overflow-y: scroll; }
  .goals-side-bar .goals-zero-state {
    padding: 22px;
    color: #a7b6c0;
    text-align: center; }
