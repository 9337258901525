/* COLORS */
/* ---------------------------- */
.pin-button {
  display: inline-block;
  min-width: 156px; }
  .pin-button .pin-content {
    display: flex;
    line-height: 16px; }
    .pin-button .pin-content img {
      width: 20px;
      height: 20px;
      margin-top: -2px; }
