/* COLORS */
/* ---------------------------- */
.outgoing-deliveries .inv-row {
  display: flex;
  min-height: 48px;
  line-height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0 36px;
  cursor: pointer; }
  .outgoing-deliveries .inv-row.selected-inv-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    background-color: white;
    font-weight: 500; }

.outgoing-deliveries .del-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.outgoing-deliveries .del-icon {
  flex: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .outgoing-deliveries .del-icon img {
    max-width: 100%; }

.outgoing-deliveries .del-task {
  flex: 3; }

.outgoing-deliveries .del-amount {
  flex: 3; }

.outgoing-deliveries .del-sent-to {
  flex: 3; }

.outgoing-deliveries .del-sent-at {
  flex: 3; }

.outgoing-deliveries .del-action {
  flex: 1; }

.outgoing-deliveries .del-action.cancel {
  color: #40b3ff;
  cursor: pointer; }
  .outgoing-deliveries .del-action.cancel:hover {
    color: #0073f9; }

.outgoing-deliveries .empty-screen-text {
  margin: 16px 0 8px 0;
  color: rgba(0, 0, 0, 0.48);
  font-style: italic; }
