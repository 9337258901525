/* COLORS */
/* ---------------------------- */
.object-list {
  width: 100%;
  min-width: 720px;
  padding-bottom: 120px; }
  .object-list .paginated-table.border-none li.header {
    border-bottom: none; }
  .object-list > .sk-spinner {
    margin: 160px auto; }
