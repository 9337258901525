/* COLORS */
/* ---------------------------- */
.object-list-header {
  display: flex;
  height: 44px;
  width: 100%;
  padding: 0 36px 0 36px;
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.28);
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-left: none;
  border-right: none; }
  .object-list-header .item {
    display: flex;
    align-items: center; }
  .object-list-header .can-sort {
    cursor: pointer; }
  .object-list-header .active {
    font-weight: bold; }
  .object-list-header i {
    font-size: 14px;
    margin-left: 4px;
    transform: scale(1, -1); }
  .object-list-header .reverse i {
    transform: none; }
