/* COLORS */
/* ---------------------------- */
.object-list-item {
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  padding: 0 36px 0 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  cursor: pointer; }
  .object-list-item:hover {
    box-shadow: rgba(202, 208, 213, 0.2) 0 2px 2px 2px; }
  .object-list-item > div:first-child {
    font-weight: bold;
    text-overflow: ellipsis; }
  .object-list-item .icon-img {
    width: 25px; }
  .object-list-item .more-options-button {
    align-self: center;
    display: flex;
    flex-direction: row-reverse; }
    .object-list-item .more-options-button .button-dropdown > button {
      height: 24px;
      width: 24px;
      background-color: transparent;
      border: 1px solid #a7b6c0;
      padding: 0; }
      .object-list-item .more-options-button .button-dropdown > button i {
        color: #a7b6c0;
        font-size: 20px; }
        .object-list-item .more-options-button .button-dropdown > button i.material-icons.more_horiz {
          font-size: 22px; }
    .object-list-item .more-options-button .button-dropdown.expanded > button {
      background-color: #0073f9; }
