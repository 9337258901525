/* COLORS */
/* ---------------------------- */
.picker-inner {
  cursor: pointer; }
  .picker-inner .emoji-mart > div.emoji-mart-bar, .picker-inner div.emoji-mart-category-label, .picker-inner div.emoji-mart-search {
    display: none; }
  .picker-inner .emoji-mart {
    border: none; }
  .picker-inner .emoji-mart-category {
    margin-top: 8px; }
  .picker-inner span.emoji-mart-emoji-custom {
    display: inline-block; }

.process-icon-picker-wrapper {
  position: relative;
  cursor: pointer; }
  .process-icon-picker-wrapper .process-icon-picker {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    border: 1px solid #cad0d5;
    border-radius: 4px;
    background-color: white;
    padding: 4px; }
    .process-icon-picker-wrapper .process-icon-picker .change-icon-icon {
      position: absolute;
      top: -5px;
      right: -5px;
      color: white;
      border-radius: 50%;
      border: 2px;
      min-width: 16px;
      max-width: 16px;
      min-height: 16px;
      max-height: 16px;
      font-size: 16px;
      background-color: #0073f9; }
    .process-icon-picker-wrapper .process-icon-picker .icon {
      flex: 1; }
