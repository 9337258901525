/* COLORS */
/* ---------------------------- */
.create-team-member .first-and-last-name {
  display: flex; }

.create-team-member .first-name-group {
  margin-right: 20px; }

.create-team-member .first-name-group, .create-team-member .last-name-group {
  flex: 1; }
