/* COLORS */
/* ---------------------------- */
.walkthrough-create-process .walkthrough-input input, .walkthrough-create-attributes .walkthrough-input input {
  height: 40px; }

.walkthrough-create-process .subtitle, .walkthrough-create-attributes .subtitle {
  color: rgba(0, 0, 0, 0.48);
  text-align: center; }

.walkthrough-create-process .walkthrough-hint {
  padding: 15px 10px;
  margin-bottom: 25px; }

.walkthrough-create-process .walkthrough-input input {
  margin-bottom: 20px; }

.walkthrough-create-attributes .walkthrough-header {
  margin-bottom: 25px; }

.walkthrough-create-attributes .subtitle {
  margin-bottom: 20px; }

.walkthrough-create-attributes .attribute-row {
  display: flex;
  margin-bottom: 20px; }
  .walkthrough-create-attributes .attribute-row > div:first-child {
    margin-right: 15px;
    width: 70%; }
  .walkthrough-create-attributes .attribute-row > div:last-child {
    width: 30%; }
    .walkthrough-create-attributes .attribute-row > div:last-child select {
      height: 40px;
      width: 100%; }
