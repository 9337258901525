/* COLORS */
/* ---------------------------- */
.goal-card-container {
  margin-left: 48px;
  margin-right: 48px; }
  .goal-card-container .goal-card {
    width: 100%;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08); }
  .goal-card-container .top-row {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    align-items: center; }
  .goal-card-container .input {
    width: 80px;
    height: 32px;
    margin: 0 8px; }
  .goal-card-container .goal-amount {
    display: flex;
    align-items: center; }
  .goal-card-container .delete {
    color: #0073f9;
    font-size: 18px;
    cursor: pointer; }
  .goal-card-container .bottom-row {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between; }
  .goal-card-container .set-by {
    font-size: 14px;
    font-style: italic;
    color: rgba(0, 0, 0, 0.28); }

.add-goal {
  color: #0073f9;
  cursor: pointer; }
