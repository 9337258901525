/* COLORS */
/* ---------------------------- */
.deliveries-page {
  width: calc(100vw - 64px);
  height: fit-content;
  flex: 1;
  padding-bottom: 100px; }
  .deliveries-page .incoming-deliveries {
    min-width: 500px;
    margin: 16px 16px 0 16px;
    padding: 22px;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.12);
    width: initial; }
  .deliveries-page .outgoing-deliveries {
    min-width: 500px;
    margin: 16px 16px 0 16px;
    padding: 22px;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.12);
    width: initial; }
  .deliveries-page .object-list-header {
    border-top: none;
    padding: 0 20px 0 20px; }
  .deliveries-page .object-list-item {
    padding: 0 20px 0 20px; }
  .deliveries-page .content-title {
    font-weight: bold;
    margin-bottom: 22px; }
