/* COLORS */
/* ---------------------------- */
.account-menu {
  min-width: 180px;
  line-height: 16px;
  z-index: 1; }
  .account-menu .account-menu-section-title {
    color: rgba(0, 0, 0, 0.3);
    font-size: 11px;
    display: block;
    margin: 16px 16px 0 16px; }

.account {
  display: flex;
  margin: 12px;
  cursor: pointer; }
  .account span.name {
    display: block;
    font-size: 14px; }
  .account span.username {
    display: block;
    color: #0073f9; }

.current-account div, .current-account span, .current-account i {
  vertical-align: middle; }

.current-account .icon {
  margin-right: 8px; }

.current-account span.name {
  color: #4f4f4f; }

.current-account span.username {
  text-align: left; }

.nav-team div.button-dropdown-content {
  top: 39px; }

h1 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.56);
  margin: 0;
  padding: 0; }

h2 {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.89); }

h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.7px;
  color: #4f4f4f;
  padding-top: 3px;
  padding-bottom: 4px;
  text-transform: uppercase; }
