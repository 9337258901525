/* COLORS */
/* ---------------------------- */
.d-top {
  height: 64px;
  padding-left: 64px;
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center; }
  .d-top .nav-logo {
    width: 64px; }
  .d-top img.logo {
    margin-left: 16px; }
  .d-top .nav-left {
    flex: 1;
    flex-grow: 1;
    margin: 0px 36px; }
    .d-top .nav-left .multiselect.inputs {
      background-color: rgba(0, 0, 0, 0.05);
      border: none; }
  .d-top .nav-right {
    flex: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 36px; }
    .d-top .nav-right img.alerts {
      color: #0073f9;
      margin: 0 36px; }
  .d-top .nav-team .button-dropdown > button {
    padding: 0; }

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999; }

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 250px;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 998; }

.dropdown.expanded .dropdown-content {
  display: block;
  z-index: 999; }
