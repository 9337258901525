/* COLORS */
/* ---------------------------- */
.card {
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  padding: 16px;
  overflow-x: hidden;
  background-color: white; }
  .card .rule {
    background-color: rgba(0, 0, 0, 0.28);
    height: 1px;
    margin: 16px;
    margin-bottom: 15px; }
  .card .cardrule-v2 {
    background-color: rgba(0, 0, 0, 0.28);
    margin-left: -16px;
    width: calc(100% + 32px);
    height: 1px; }
  .card.big {
    padding: 16px;
    border-radius: 8px;
    margin-right: 48px; }
    .card.big .rule {
      margin: 32px;
      margin-bottom: 31px; }
  .card.nopadding {
    padding: 0px; }
    .card.nopadding .cardrule-v2 {
      margin-left: 0;
      width: 100%; }

.user-icon {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.28);
  border-radius: 16px;
  margin-right: 8px; }
  .user-icon span {
    color: white;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    display: block; }

.select-circle {
  width: 16px;
  padding-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.28);
  border-radius: 16px; }
  .select-circle.selected {
    border-color: #0073f9;
    background-color: #0073f9; }

body div.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 999; }
  body div.dialog-container .dialog-shim {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 997; }
  body div.dialog-container .dialog-card {
    max-width: 400px;
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 999; }
  body div.dialog-container .create-process-buttons {
    display: flex;
    justify-content: flex-end; }
