@import url("https://fonts.googleapis.com/css?family=Overpass+Mono|Overpass:400,700");
/* COLORS */
/* ---------------------------- */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

img:before {
  background-image: url(/dashboard/static/dashboard/img/default@3x.png); }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"); }

.material-icons {
  font-family: 'Material Icons';
  font-style: normal; }

label {
  font-size: 12px;
  line-height: 16px;
  padding: 8px 8px 8px 0; }

a, a:hover, a:visited span {
  color: inherit;
  text-decoration: none; }

.filters {
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .filters .Select-placeholder {
    padding: 0; }
  .filters > div {
    flex: 2;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0px 8px; }
  .filters > div:first-child {
    flex: 3;
    border: none; }

.multiselect i {
  font-size: 14px;
  line-height: 36px;
  vertical-align: middle;
  margin-left: 8px; }

.multiselect > div {
  border-left: none; }

ul div.menuContentWrapper div.rdr-Days {
  white-space: normal; }

.factorymap {
  margin-top: 50px; }

.processTile {
  fill: white; }

.factorymap text {
  font-size: 0.85em;
  line-height: 50px; }

.connectionTemp {
  fill: #90CAF9; }

.trouble {
  max-width: 500px;
  margin: 0 auto; }
  .trouble h6 {
    text-align: center; }
  .trouble p, .trouble li {
    font-size: 12px;
    line-height: 16px; }
  .trouble li {
    margin-bottom: 10px; }
  .trouble a {
    color: #0073F9; }

div.labelPrinter {
  padding-top: 50px;
  padding-bottom: 50px; }
  div.labelPrinter .stuff {
    max-width: 300px;
    margin: 0 auto;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.12);
    padding: 20px;
    background-color: white;
    overflow: hidden; }
  div.labelPrinter input {
    line-height: 34px;
    font-size: 15.2px;
    border: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
    div.labelPrinter input:focus {
      outline: none; }
  div.labelPrinter section {
    margin-bottom: 30px; }
  div.labelPrinter .inputLabel {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6); }
  div.labelPrinter .marginer {
    padding-top: 40px; }
    div.labelPrinter .marginer h2 {
      text-align: center;
      line-height: 48px; }
  div.labelPrinter button {
    padding: 8px 16px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    background-color: #0073F9;
    width: 100%; }
  div.labelPrinter .regularprint {
    max-height: 1000px; }
  div.labelPrinter .small {
    max-height: 0;
    overflow: hidden;
    z-index: 997; }
  div.labelPrinter .reprint {
    margin: -20px;
    padding: 20px;
    background-color: white;
    z-index: 999; }
    div.labelPrinter .reprint:focus {
      outline: none; }
  div.labelPrinter button.expandReprint {
    display: block;
    color: #0073F9;
    background-color: white;
    font-size: 13px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
    text-align: left; }
    div.labelPrinter button.expandReprint span {
      flex: 1; }
    div.labelPrinter button.expandReprint i {
      text-align: right;
      flex: 0;
      color: #0073F9;
      font-size: 13px;
      line-height: 16px;
      vertical-align: middle; }
    div.labelPrinter button.expandReprint.expanded i {
      visibility: inherit; }

.labelSVG {
  background-color: white;
  font-family: 'Overpass', 'Overpass Mono'; }

#qrtest {
  width: 100%; }
  #qrtest img {
    margin: 40px auto;
    height: 60px; }

div#blocker, #canvastest {
  position: absolute;
  height: 300px;
  width: 500px;
  background-color: #eee;
  top: 0;
  left: 0; }

div#blocker, #canvastest {
  display: none; }

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.dialog-shim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000; }

.dialog-box {
  margin: 0 auto;
  margin-top: 200px;
  background-color: white;
  max-width: 400px;
  min-width: 400px;
  padding: 16px;
  border-radius: 4px; }
  .dialog-box .dialog-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    display: block; }
  .dialog-box .dialog-text {
    display: block;
    margin: 16px 0px; }
  .dialog-box .dialog-button {
    border: none;
    outline: none;
    background-color: #0073F9;
    margin-left: 16px;
    color: white;
    font-size: 14px;
    border-radius: 2px;
    padding: 8px 12px; }
  .dialog-box .dialog-cancel {
    color: #0073F9;
    background-color: white; }
  .dialog-box .dialog-actions {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 16px -16px;
    margin-bottom: -16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
