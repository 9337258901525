/* COLORS */
/* ---------------------------- */
.no-items {
  color: rgba(0, 0, 0, 0.48);
  font-style: italic;
  margin-left: 36px; }

.paginated-table ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.paginated-table .pagination {
  width: 100%;
  font-size: 14px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 36px 0 36px; }
  .paginated-table .pagination .pagination-count {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.68);
    margin-right: 8px; }
  .paginated-table .pagination .pagination-links {
    color: #0073f9;
    display: flex;
    align-items: center; }
    .paginated-table .pagination .pagination-links .pagination-pipe {
      padding: 0 8px; }
    .paginated-table .pagination .pagination-links .disabled {
      color: rgba(0, 0, 0, 0.28); }
  .paginated-table .pagination .pagination-link {
    vertical-align: middle;
    cursor: pointer; }

.paginated-table li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.28); }
  .paginated-table li.header {
    border-top: 1px solid rgba(0, 0, 0, 0.28); }

.paginated-table.border-none li {
  border-bottom: 0px;
  border-top: 0px; }
  .paginated-table.border-none li.header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.28); }
