/* COLORS */
/* ---------------------------- */
.inventory-list-container .inv-row {
  display: flex;
  min-height: 48px;
  line-height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0 36px;
  cursor: pointer; }
  .inventory-list-container .inv-row.selected-inv-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    background-color: white;
    font-weight: 500; }

.inventory-list-container .inv-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.inventory-list-container .inv-icon {
  flex: 0 0 24px;
  display: flex;
  align-items: center;
  margin-right: 8px; }

.inventory-list-container .inv-title {
  flex: 4; }

.inventory-list-container .inv-category {
  flex: 1.5; }

.inventory-list-container .inv-code {
  flex: 2; }

.inventory-list-container .inv-amount {
  flex: 1.5; }

.inventory-list-container .inv-cost {
  flex: 1; }

.inventory-list-container li:first-child .inv-row {
  border-top: 1px solid rgba(0, 0, 0, 0.08); }

.inventory-list-container .inv-icon-circ {
  border-radius: 12px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center; }
