/* COLORS */
/* ---------------------------- */
.processes-container {
  width: calc(100vw); }

div.process-filter {
  margin-left: 36px;
  max-width: 400px; }

div.processes .code {
  flex: 2; }

div.processes .name {
  flex: 4; }

div.processes .category {
  flex: 4; }

div.processes .owner {
  flex: 3; }

div.processes .date {
  flex: 4; }

div.processes .icon {
  flex: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center; }

div.processes .description {
  flex: 4; }

div.processes .default-amount {
  flex: 4; }

div.processes .last-used {
  flex: 3; }

div.processes .more-options-button {
  flex: 1; }
