/* COLORS */
/* ---------------------------- */
.inventory-filters {
  display: flex;
  flex-direction: column;
  margin: 24px 36px;
  color: rgba(0, 0, 0, 0.48); }
  .inventory-filters .row {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
    .inventory-filters .row .checkboxes {
      display: flex;
      flex-grow: 1; }
    .inventory-filters .row .category-boxes-container {
      display: flex;
      width: 600px;
      color: rgba(0, 0, 0, 0.28); }
      .inventory-filters .row .category-boxes-container .category-box {
        flex: 1;
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        background-color: white;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
        border-radius: 4px; }
        .inventory-filters .row .category-boxes-container .category-box .category-box-title {
          flex: 1;
          display: flex;
          justify-content: center;
          flex-direction: column;
          color: rgba(0, 0, 0, 0.48);
          text-align: center;
          font-size: 18px; }
        .inventory-filters .row .category-boxes-container .category-box .category-box-cost {
          flex: 1.6;
          display: flex;
          justify-content: center;
          padding-top: 12px;
          text-align: center;
          color: rgba(0, 0, 0, 0.48);
          font-size: 24px;
          font-weight: bold; }
