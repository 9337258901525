/* COLORS */
/* ---------------------------- */
.raw-materials-timeline {
  margin-bottom: 10px;
  display: flex;
  justify-content: center; }
  .raw-materials-timeline .bar {
    fill: #0073f9; }
  .raw-materials-timeline .grayed-out-bar {
    fill: #a7b6c0; }
  .raw-materials-timeline .stop-left {
    stop-color: white; }
  .raw-materials-timeline .stop-right {
    stop-color: #a7b6c0; }
  .raw-materials-timeline .axis line {
    fill: none;
    stroke-width: 1px;
    shape-rendering: crispEdges; }
  .raw-materials-timeline .x path {
    stroke: #a7b6c0; }
  .raw-materials-timeline .y path {
    stroke: rgba(0, 0, 0, 0.68); }
  .raw-materials-timeline .x line {
    stroke: #cad0d5; }
  .raw-materials-timeline .y line {
    display: none; }
  .raw-materials-timeline .x text {
    fill: #a7b6c0;
    font-size: 10px; }
  .raw-materials-timeline .y-axis-text {
    fill: rgba(0, 0, 0, 0.68);
    font-size: 12px;
    font-weight: bold; }
  .raw-materials-timeline .y-axis-text-danger {
    fill: #dd5151;
    font-size: 12px;
    font-weight: bold; }
  .raw-materials-timeline .not-enough-usage-data {
    font-size: 12px;
    font-style: italic;
    fill: #a7b6c0;
    paint-order: stroke;
    stroke: #f6f8f9;
    stroke-width: 6;
    stroke-linecap: butt;
    stroke-linejoin: miter; }
  .raw-materials-timeline .not-enough-usage-data.all {
    text-anchor: middle; }
