.activity {
  min-width: 1060px;
  width: 100%;
  background-color: white; }
  .activity .activity-process {
    width: 100%;
    background-color: white; }
    .activity .activity-process .no {
      visibility: hidden;
      padding-left: 16px; }
      .activity .activity-process .no a {
        border: none;
        outline: none;
        border-radius: 40px;
        padding: 6px;
        background-color: rgba(255, 255, 255, 0.5);
        color: rgba(0, 0, 0, 0.1);
        font-size: 14px;
        line-height: 14px;
        display: inline-block;
        height: 26px;
        width: 26px; }
        .activity .activity-process .no a i.material-icons {
          font-size: 14px;
          vertical-align: middle; }
      .activity .activity-process .no .loading-indicator {
        height: 44px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center; }
    .activity .activity-process.expanded .no, .activity .activity-process.expanded .no a i {
      visibility: visible;
      color: #0073F9; }
    .activity .activity-process .activity-process-row {
      display: flex;
      flex-direction: row;
      vertical-align: middle;
      color: rgba(0, 0, 0, 0.62);
      cursor: pointer; }
      .activity .activity-process .activity-process-row .no {
        flex: 0; }
      .activity .activity-process .activity-process-row div {
        vertical-align: middle;
        flex: 3;
        text-align: right; }
        .activity .activity-process .activity-process-row div span {
          display: inline-block;
          line-height: 32px;
          vertical-align: middle; }
      .activity .activity-process .activity-process-row div.min {
        flex: 0;
        flex-basis: 32px;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        text-align: center; }
      .activity .activity-process .activity-process-row div.process-name {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.7);
        text-align: left;
        flex: 3; }
      .activity .activity-process .activity-process-row img {
        width: 16px; }
      .activity .activity-process .activity-process-row .tiny {
        flex: 2; }
    .activity .activity-process.expanded {
      background-color: rgba(0, 0, 0, 0.03);
      border-radius: 4px;
      border: none;
      overflow: hidden; }
      .activity .activity-process.expanded .activity-process-header {
        background-color: #0073F9;
        color: white; }
        .activity .activity-process.expanded .activity-process-header div.process-name {
          color: white; }
    .activity .activity-process .activity-process-header {
      border-top: 1px solid rgba(0, 0, 0, 0.05); }
    .activity .activity-process .process-origin-header, .activity .activity-process .process-origin-header > div:first-child {
      color: rgba(0, 0, 0, 0.8); }
    .activity .activity-process .process-origin-header {
      padding: 6px 16px; }
    .activity .activity-process .process-origin-task {
      padding: 4px 16px; }
      .activity .activity-process .process-origin-task a:hover {
        color: primary; }
    .activity .activity-process .expanded-origin {
      background-color: rgba(0, 0, 0, 0.03); }
      .activity .activity-process .expanded-origin > div:last-child {
        padding-bottom: 12px; }
      .activity .activity-process .expanded-origin .task-list div > span {
        color: rgba(0, 0, 0, 0.4); }
  .activity .arrow {
    vertical-align: middle;
    font-size: 16px;
    line-height: 16px; }
  .activity .min {
    border-radius: 20px; }
    .activity .min.img {
      background-color: white; }
    .activity .min img, .activity .min span {
      vertical-align: middle;
      display: inline-block; }
    .activity .min img {
      margin: 8px auto; }
  .activity .object-list.activity-log {
    padding-bottom: 0; }
  .activity .content {
    margin-bottom: 24px; }
  .activity .activity-page-help-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 24px;
    margin-bottom: 76px; }
  .activity .activity-page-help {
    background-color: #0073F9;
    margin-bottom: 24px;
    color: white;
    padding: 12px;
    border-radius: 8px;
    width: 400px;
    cursor: pointer; }
  .activity .activity-page-help-header {
    font-weight: bold;
    padding-bottom: 12px; }
  .activity .activity-page-help-link {
    font-weight: bold; }
  .activity .activity-page-forward {
    vertical-align: middle; }
  .activity .activity-page-forward-i {
    vertical-align: middle; }
