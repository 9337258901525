/* COLORS */
/* ---------------------------- */
.d-nav {
  z-index: 901;
  background-color: #f6f8f9;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 64px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.28);
  background-color: #2a2d34;
  color: white;
  font-size: 13px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 5px 0 5px 5px rbga(0, 0, 0, 0.1); }
  .d-nav .nav-group {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    .d-nav .nav-group li a {
      display: inline-block;
      padding: 16px 0 16px 16px;
      width: 100%;
      color: rgba(255, 255, 255, 0.5); }
      .d-nav .nav-group li a img {
        opacity: 0.5; }
      .d-nav .nav-group li a .nav-icon {
        height: 16px;
        vertical-align: top;
        opacity: 0.4;
        padding-right: 8px; }
      .d-nav .nav-group li a.active {
        color: white;
        font-weight: bold; }
        .d-nav .nav-group li a.active img {
          opacity: 1; }

div .bar {
  flex: 1; }

.nav-feedback {
  flex: 0;
  align-items: flex-end;
  margin: 16px;
  font-size: 12px;
  line-height: 18px; }
  .nav-feedback span {
    display: block; }
  .nav-feedback a {
    text-decoration: underline; }
  .nav-feedback .expand-i {
    visibility: hidden;
    padding-left: 4px;
    font-size: 12px;
    vertial-align: bottom;
    margin-botom: 2px; }
  .nav-feedback a:hover .expand-i {
    visibility: visible; }

.navbar-help {
  width: 64px;
  text-align: center;
  margin-bottom: 24px; }

.navbar {
  background-color: #f6f8f9;
  min-width: 64px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  border-right: 1px solid rgba(0, 0, 0, 0.48);
  color: white; }
  .navbar ul {
    list-style-type: none;
    padding: 0px; }

.nav-tooltip {
  position: relative;
  display: inline-block;
  width: 100%; }

.nav-tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  position: absolute;
  z-index: 1;
  top: calc(50% - 15px);
  left: 69px;
  white-space: nowrap; }

.nav-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent; }

.nav-tooltip:hover .tooltiptext {
  visibility: visible; }
