/* COLORS */
/* ---------------------------- */
.recipe {
  display: flex; }
  .recipe .recipe-item-field {
    color: rgba(0, 0, 0, 0.68);
    display: flex;
    flex: 1;
    text-align: left;
    overflow: hidden;
    max-height: 20px;
    white-space: nowrap; }
    .recipe .recipe-item-field > span {
      padding-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis; }
  .recipe .list-item-recipe-yield {
    color: #a7b6c0;
    -webkit-font-smoothing: antialiased;
    max-height: 20px;
    width: 284px; }
    .recipe .list-item-recipe-yield > span {
      padding: 0; }
  .recipe .process-attributes-header {
    display: flex; }
    .recipe .process-attributes-header > span {
      flex: 1; }
    .recipe .process-attributes-header > button {
      flex: 0;
      white-space: nowrap; }
  .recipe img, .recipe i {
    height: 16px;
    margin-right: 4px; }

.recipe-expanded {
  margin-top: 16px; }

.recipe.create-recipe {
  flex-direction: column; }
  .recipe.create-recipe .recipe-stage-select {
    display: flex; }

.element-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.process-type-link {
  vertical-align: middle;
  cursor: pointer; }
  .process-type-link i {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.5); }
