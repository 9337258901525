/* COLORS */
/* ---------------------------- */
.activity-filters {
  padding: 32px;
  color: rgba(0, 0, 0, 0.48); }
  .activity-filters .row {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
  .activity-filters .datepicker {
    width: 200px;
    margin-right: 20px; }
  .activity-filters .select-container {
    width: 300px;
    margin-right: 20px;
    position: relative; }
    .activity-filters .select-container > i {
      font-size: 20px;
      position: absolute;
      top: 9px;
      left: 8px; }
    .activity-filters .select-container .ant-select {
      width: 100%; }
    .activity-filters .select-container .Select-control {
      background-color: transparent;
      padding-left: 23px; }
    .activity-filters .select-container .Select--multi .Select-input {
      margin-left: 5px; }
    .activity-filters .select-container .Select-placeholder {
      padding-left: 28px; }
  .activity-filters .input-container {
    width: 200px;
    margin-right: 20px;
    position: relative; }
  .activity-filters .input {
    padding-left: 36px;
    border-color: rgba(0, 0, 0, 0.28); }
    .activity-filters .input::placeholder {
      color: rgba(0, 0, 0, 0.48); }
  .activity-filters .checkboxes {
    display: flex;
    flex-grow: 1; }
