/* COLORS */
/* ---------------------------- */
.activity div.activity-list-item {
  width: 100%;
  display: flex;
  align-items: center; }
  .activity div.activity-list-item:hover .download, .activity div.activity-list-item:hover .chart {
    visibility: visible; }

.activity .icon {
  flex: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .activity .icon img {
    max-width: 100%; }

.activity .process-code {
  flex: 1; }

.activity .process-name {
  flex: 3; }

.activity .category {
  flex: 2; }

.activity .product-code {
  flex: 3; }

.activity .runs {
  flex: 2; }

.activity .outputs {
  flex: 3; }

.activity .view-all-tasks {
  flex: 2;
  color: #40b3ff; }

.activity .download {
  flex: 1;
  visibility: hidden; }

.activity .chart {
  flex: 1;
  visibility: hidden;
  height: 26px;
  width: 43.5px; }
  .activity .chart.trashed-process {
    pointer-events: none;
    cursor: default; }
