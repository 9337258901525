/* COLORS */
/* ---------------------------- */
.process-attributes {
  padding: 24px 36px;
  max-width: 740px; }
  .process-attributes .process-attributes-header {
    display: flex; }
    .process-attributes .process-attributes-header > span {
      flex: 1; }
    .process-attributes .process-attributes-header > button {
      flex: 0;
      white-space: nowrap; }
  .process-attributes .process-attr-list-header {
    font-weight: 700;
    display: flex;
    margin-left: 48px;
    margin-right: 32px;
    padding: 8px 16px; }
    .process-attributes .process-attr-list-header > span {
      flex: 1; }
