/* COLORS */
/* ---------------------------- */
.dashboard {
  width: 100%; }
  .dashboard .dashboard-content {
    display: flex; }
    .dashboard .dashboard-content > div:last-child {
      flex: 0;
      flex-basis: 260px;
      width: 260px; }
    .dashboard .dashboard-content > div:first-child {
      flex: 1; }
  .dashboard div.panel {
    padding-top: 0;
    display: flex;
    width: 300px;
    position: absolute;
    top: 64px;
    bottom: 0;
    right: 0;
    margin-right: 0px;
    padding-bottom: 0;
    margin-bottom: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08); }
    .dashboard div.panel .little-header {
      margin-left: 16px; }
    .dashboard div.panel .alerts-container {
      flex: 2;
      background-color: #f6f8f9;
      overflow-y: auto;
      display: flex;
      flex-direction: column; }

.goals-card {
  flex: 1; }

.updates {
  background-color: #0073f9;
  color: rgba(255, 255, 255, 0.7);
  -webkit-font-smoothing: antialiased;
  margin-left: 36px;
  margin-bottom: 16px; }
  .updates a {
    color: white;
    border-bottom: 1px dotted white; }

.home-tabs {
  display: flex;
  color: rgba(0, 0, 0, 0.48);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-left: 36px; }
  .home-tabs .home-tab {
    padding: 8px 0;
    line-height: 20px;
    margin-right: 24px;
    cursor: pointer; }
    .home-tabs .home-tab.active {
      color: #0073f9;
      border-bottom: 4px solid #0073f9;
      padding-bottom: 4px; }
