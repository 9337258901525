/* COLORS */
/* ---------------------------- */
.section-tabs {
  display: flex;
  align-items: center;
  background-color: #0073f9;
  padding: 0 10px;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); }
  .section-tabs .tab {
    font-size: 20px;
    height: 56px;
    cursor: pointer;
    text-align: center;
    padding: 18px 26px;
    min-width: 100px;
    box-sizing: border-box;
    border-bottom: solid 4px transparent;
    opacity: 0.64;
    color: white; }
    .section-tabs .tab:hover {
      opacity: 0.82; }
  .section-tabs .active {
    border-bottom: solid 4px white;
    color: rbga(255, 255, 255, 1);
    opacity: 1; }
    .section-tabs .active:hover {
      opacity: 1; }
