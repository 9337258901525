.reset-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .reset-box a {
    color: #2588FF;
    text-align: center;
    margin: 32%; }

#info-message {
  margin: 40px; }
