/* COLORS */
/* ---------------------------- */
.walkthrough-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #0073f9;
  color: #0073f9;
  font-size: 14px;
  cursor: pointer; }

.walkthrough-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
  -webkit-font-smoothing: antialiased;
  font-size: 14px; }

.walkthrough-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent; }

.walkthrough-tooltip:hover .tooltiptext {
  visibility: visible; }
