/* COLORS */
/* ---------------------------- */
.app-section-header {
  font-size: 20px;
  height: 56px;
  line-height: 56px;
  color: white;
  padding: 0 36px;
  background-color: #0073f9;
  -webkit-font-smoothing: antialiased; }
