/* COLORS */
/* ---------------------------- */
.task-recurrent-attribute {
  max-width: 400px;
  min-width: 400px; }
  .task-recurrent-attribute .log {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .task-recurrent-attribute .log > div {
      flex: 1; }
    .task-recurrent-attribute .log .value {
      max-width: 280px;
      word-wrap: break-word; }
    .task-recurrent-attribute .log .time {
      text-align: right;
      color: rgba(0, 0, 0, 0.28); }
  .task-recurrent-attribute .attribute-zero-state {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    color: rgba(0, 0, 0, 0.3); }
  .task-recurrent-attribute .input-container {
    margin-top: 16px; }
