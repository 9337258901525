/* COLORS */
/* ---------------------------- */
.task-select {
  width: 100%;
  max-width: 900px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  align-items: center; }
  .task-select i {
    line-height: 34px;
    font-size: 16px;
    padding: 0 8px;
    flex: 0 auto; }
