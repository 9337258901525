/* COLORS */
/* ---------------------------- */
textarea.textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  padding: 8px;
  resize: none;
  outline: none;
  color: rgba(0, 0, 0, 0.88);
  font-family: Apercu;
  font-size: 14px; }
  textarea.textarea:focus {
    border-color: #0073f9; }
  textarea.textarea::placeholder {
    color: rgba(0, 0, 0, 0.48);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
