/* COLORS */
/* ---------------------------- */
.dropzone-container .dropzone {
  color: #C0C0C0;
  height: 90px;
  border: dashed 2px #DBDBDB;
  border-radius: 2px;
  background-color: #EBEBEB;
  text-align: center;
  padding: 14px 40px;
  margin-top: 50px;
  font-size: 12px;
  line-height: 14px; }
  .dropzone-container .dropzone div {
    margin-top: 8px; }

.dropzone-container .active {
  border: solid 2px #66CC66; }

.dropzone-container .file-list {
  margin-top: 22px; }
