/* COLORS */
/* ---------------------------- */
body div.dialog-container .form-dialog {
  background-color: white;
  width: 380px;
  border-radius: 4px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.09); }
  body div.dialog-container .form-dialog .body {
    padding: 16px; }
  body div.dialog-container .form-dialog button {
    width: 100%;
    height: 44px;
    border-radius: 4px; }
