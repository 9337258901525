/* COLORS */
/* ---------------------------- */
.task-form {
  max-width: 500px;
  min-width: 500px; }
  .task-form .form-label {
    color: #a7b6c0;
    margin-bottom: 8px; }
  .task-form .attribute-field {
    margin-bottom: 36px; }
  .task-form .boolean-container {
    display: flex;
    align-items: center;
    height: 38px;
    margin-left: 24px; }
    .task-form .boolean-container .switch {
      height: 16px;
      margin-left: 20px; }
  .task-form .input-container {
    position: relative;
    display: flex;
    max-height: 38px; }
  .task-form .input-container input {
    max-width: 400px;
    min-width: 400px; }
    .task-form .input-container input.attr-error {
      border-color: #dd5151; }
  .task-form .form-buttons {
    display: none;
    position: absolute;
    top: 6px;
    right: 0; }
