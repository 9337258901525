/* COLORS */
/* ---------------------------- */
.tabs-wrapper {
  display: flex; }
  .tabs-wrapper .tab-wrapper {
    display: flex;
    margin-right: 24px;
    cursor: pointer;
    color: #a7b6c0;
    padding-bottom: 0px;
    border-bottom: 4px solid white; }
    .tabs-wrapper .tab-wrapper .icon-wrapper {
      flex: 1;
      margin-left: -6px;
      margin-top: -2px;
      height: 24px; }
    .tabs-wrapper .tab-wrapper .title {
      flex: 1; }
    .tabs-wrapper .tab-wrapper.active-tab {
      color: #0073f9;
      border-bottom: 4px solid #0073f9; }
