/* COLORS */
/* ---------------------------- */
.login-box form {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .login-box form > input, .login-box form > button, .login-box form > span {
    margin: 12px; }
  .login-box form input {
    border: 1px solid #DBE0E4;
    border-radius: 4px;
    width: 300px;
    color: rgba(0, 0, 0, 0.68);
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    outline: none; }
    .login-box form input:focus {
      border-color: #0073f9; }
    .login-box form input::placeholder {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .login-box form button {
    background-color: #2588FF;
    height: 36px;
    color: white;
    font-size: 14px; }

.failMessage {
  color: #dd5151;
  max-width: 300px;
  display: block; }
