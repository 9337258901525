/* COLORS */
/* ---------------------------- */
div.product-filter {
  margin-left: 36px;
  max-width: 400px; }

.products {
  width: 100%; }
  .products .object-list-item {
    cursor: pointer; }
  .products .paginated-table.border-none li.header {
    border-bottom: none; }
  .products .code {
    flex: 4; }
  .products .name {
    flex: 8; }
  .products .owner {
    flex: 4; }
  .products .date {
    flex: 4; }
  .products .more-options-button {
    flex: 1; }

.products-card-inventory .products-card-inventory-item {
  display: flex;
  flex-direction: row;
  line-height: 38px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.28);
  cursor: pointer; }
  .products-card-inventory .products-card-inventory-item > div {
    flex: 1; }
  .products-card-inventory .products-card-inventory-item > div:last-child {
    text-align: right; }

.recipe-content {
  padding: 24px; }

.recipe-content-text {
  padding-top: 12px;
  padding-bottom: 24px; }

.recipe-content-header {
  font-weight: bold;
  padding-bottom: 24px;
  font-size: 16px; }

.learn-how {
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold; }
