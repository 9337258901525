/* COLORS */
/* ---------------------------- */
.dialog-header {
  padding: 20px 16px 16px;
  min-height: 52px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.88); }
  .dialog-header i {
    font-size: 16px;
    float: right;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.28); }
