/* COLORS */
/* ---------------------------- */
.walkthrough {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f8f9;
  z-index: 999;
  overflow: scroll; }
  .walkthrough > div {
    margin: 40px auto; }
  .walkthrough div.card {
    padding: 0;
    max-width: 404px;
    box-shadow: rgba(0, 0, 0, 0.08) 0 0 16px;
    margin: 0 auto; }
  .walkthrough > img {
    height: 46px;
    margin: 20px auto 0;
    display: block; }
