/* COLORS */
/* ---------------------------- */
button {
  padding: 8px 12px 8px 12px;
  font-size: 12px;
  line-height: 16px;
  background-color: #0073f9;
  color: white;
  border-radius: 6px;
  border: 0;
  outline: none;
  display: block;
  cursor: pointer; }
  button.secondary {
    background-color: rgba(0, 0, 0, 0);
    color: #0073f9; }
  button.link {
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    color: #0073f9; }
  button:disabled {
    background-color: #c3c3c3;
    color: #838383;
    cursor: not-allowed; }
