/* COLORS */
/* ---------------------------- */
.new-user-checklist-container {
  width: calc(100vw - 64px); }
  .new-user-checklist-container .new-user-checklist {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 36px;
    margin-bottom: 100px; }
  .new-user-checklist-container .good-morning {
    padding-top: 24px;
    font-size: 40px;
    line-height: 66px; }
  .new-user-checklist-container .checklist-help {
    margin-bottom: 24px; }
    .new-user-checklist-container .checklist-help i {
      font-size: 14px;
      line-height: 22px;
      vertical-align: middle; }
    .new-user-checklist-container .checklist-help a {
      color: #0073f9; }
  .new-user-checklist-container .checklist-title {
    width: 600px;
    font-weight: 700; }
