/* COLORS */
/* ---------------------------- */
.submissions {
  margin-bottom: 16px; }
  .submissions span {
    display: inline-block; }
  .submissions .recent-subs div {
    flex: 0;
    display: inline-block;
    max-width: 80px;
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .submissions .recent-subs span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0;
    background-color: #ffd754;
    padding: 2px 6px;
    border-radius: 2px;
    font-size: 12px; }
  .submissions .submission-title {
    margin-left: 4px;
    margin-bottom: 4px;
    background-color: #fff3cc;
    padding: 2px 6px;
    display: inline-block;
    border-radius: 2px; }
