/* COLORS */
/* ---------------------------- */
.create-process-dialog .icon-name-abbreviation {
  display: flex; }
  .create-process-dialog .icon-name-abbreviation .icon-group {
    flex: 1; }
  .create-process-dialog .icon-name-abbreviation .abbreviation-group {
    flex: 2;
    margin-left: 16px; }
  .create-process-dialog .icon-name-abbreviation .name-group {
    flex: 4;
    margin-left: 16px; }

.create-process-dialog .output-quantity {
  display: flex; }
  .create-process-dialog .output-quantity .number {
    flex: 1;
    margin-right: 20px; }
  .create-process-dialog .output-quantity .unit {
    flex: 4; }

.create-process-dialog .process-category {
  display: flex; }
  .create-process-dialog .process-category .select {
    flex: 1; }
