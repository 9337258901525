/* COLORS */
/* ---------------------------- */
.walkthrough-form .next-to {
  display: flex; }
  .walkthrough-form .next-to > div:first-child {
    padding-right: 20px; }

.subtitle {
  color: rgba(0, 0, 0, 0.48);
  text-align: center;
  margin-bottom: 20px; }

.walkthrough-input {
  display: flex;
  flex-direction: column;
  position: relative; }
  .walkthrough-input input {
    border: 1px solid #DBE0E4;
    outline: none;
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px; }
  .walkthrough-input .title {
    margin-bottom: 4px;
    color: #A1A7AC; }
  .walkthrough-input img {
    position: absolute;
    margin: 8px;
    top: 0;
    right: 0; }

.walkthrough-button {
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  width: 100%; }

.walkthrough-header {
  display: block;
  text-align: center;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 36px; }

.walkthrough-hint {
  display: flex;
  background-color: #F0F5FA;
  color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 20px 50px;
  align-items: center; }
  .walkthrough-hint img {
    opacity: 0.3;
    margin-right: 8px; }

.walkthrough-container {
  padding: 40px; }

.walkthrough-link {
  color: #0073f9; }
