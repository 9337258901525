.product-edit-form .code-and-name {
  display: flex; }
  .product-edit-form .code-and-name .code-group {
    flex: 1; }

.product-edit-form .name-group {
  flex: 2;
  margin-left: 20px; }

.product-edit-form .button-wide {
  flex: 1;
  width: 100%; }
