/* COLORS */
/* ---------------------------- */
.checkbox {
  margin-right: 24px; }
  .checkbox label {
    display: flex;
    align-items: center;
    cursor: pointer; }
  .checkbox input {
    margin-right: 12px; }
