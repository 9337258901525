/* COLORS */
/* ---------------------------- */
.production-trends-wrapper {
  padding-top: 28px;
  padding-bottom: 80px;
  z-index: 0;
  overflow-x: scroll; }
  .production-trends-wrapper .production-trends {
    min-width: 916px;
    max-width: 916px; }
    .production-trends-wrapper .production-trends .options {
      display: flex;
      justify-content: space-between;
      margin-left: 12px;
      margin-right: 50px; }
    .production-trends-wrapper .production-trends .process-product-icon {
      margin-left: 16px;
      width: 24px;
      opacity: 0.7; }
    .production-trends-wrapper .production-trends .production-trends-filters {
      display: flex;
      align-items: center; }
    .production-trends-wrapper .production-trends .ant-select {
      width: 200px;
      margin-left: 8px; }
    .production-trends-wrapper .production-trends .buttons {
      display: inline-flex; }
      .production-trends-wrapper .production-trends .buttons button {
        display: flex;
        margin-left: 12px;
        height: 32px; }
    .production-trends-wrapper .production-trends .prod-trends-download {
      font-size: 20px;
      line-height: 20px;
      margin-top: -2px; }
    .production-trends-wrapper .production-trends .every-month-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 18px;
      margin-bottom: 12px; }
    .production-trends-wrapper .production-trends .cumulatives {
      display: flex;
      margin-top: 32px; }
    .production-trends-wrapper .production-trends .step-chart-subtitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-right: 48px; }
    .production-trends-wrapper .production-trends .activity-link {
      display: flex;
      color: #0073f9;
      vertical-align: middle;
      line-height: 20px; }
      .production-trends-wrapper .production-trends .activity-link i {
        font-size: 16px;
        line-height: 20px;
        margin-right: 6px; }
    .production-trends-wrapper .production-trends .trends-subtitle {
      font-size: 14px;
      margin-top: 4px;
      margin-left: 48px;
      display: flex;
      color: rgba(0, 0, 0, 0.88);
      -webkit-font-smoothing: antialiased; }
    .production-trends-wrapper .production-trends .trends-content {
      margin-left: 12px;
      margin-top: 32px; }
