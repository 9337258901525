/* COLORS */
/* ---------------------------- */
.task-header {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 36px;
  background-color: #0073f9;
  -webkit-font-smoothing: antialiased; }
  .task-header.warning {
    background-color: #fea300; }
  .task-header.flagged {
    background-color: #dd5151; }
    .task-header.flagged .header-button {
      color: #dd5151; }
  .task-header.ancestor_is_flagged {
    background-color: #e99090; }
  .task-header .title {
    display: flex;
    align-items: center;
    font-size: 20px; }
    .task-header .title i {
      cursor: pointer;
      margin-right: 8px;
      opacity: 0.5; }
  .task-header .task-ancestor-flag {
    display: flex;
    align-items: center; }
    .task-header .task-ancestor-flag i {
      font-size: 20px;
      margin-right: 8px; }
  .task-header .header-button {
    background-color: white;
    color: #0073f9;
    font-size: 15px; }
  .task-header .add_warning_button {
    margin-left: auto;
    margin-right: 10px; }
  .task-header .flagged-ancestors-review {
    border-bottom: 1px dashed white;
    cursor: pointer; }
