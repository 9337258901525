/* COLORS */
/* ---------------------------- */
.production-planning-container {
  width: calc(100vw - 64px);
  height: fit-content; }
  .production-planning-container .production-planning-body {
    display: flex;
    flex-direction: row; }
    .production-planning-container .production-planning-body .production-planning-main {
      flex: 3.5;
      padding: 30px 50px; }
      .production-planning-container .production-planning-body .production-planning-main .empty-screen-text {
        margin: 36px 0;
        color: rgba(0, 0, 0, 0.48);
        font-style: italic; }
      .production-planning-container .production-planning-body .production-planning-main .warning-list-container {
        padding: 16px 22px;
        background-color: white;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.12);
        margin-bottom: 16px; }
      .production-planning-container .production-planning-body .production-planning-main .warning-title {
        display: flex; }
      .production-planning-container .production-planning-body .production-planning-main .in-progress-list-container {
        padding: 22px;
        background-color: white;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.12); }
      .production-planning-container .production-planning-body .production-planning-main .content-title {
        font-weight: bold;
        margin-bottom: 22px; }
      .production-planning-container .production-planning-body .production-planning-main .object-list-header {
        border-top: none;
        padding: 0 36px 0 36px; }
      .production-planning-container .production-planning-body .production-planning-main .object-list-item {
        padding: 0 36px 0 36px; }
