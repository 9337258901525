/* COLORS */
/* ---------------------------- */
.in-progress-list-container .inv-row {
  display: flex;
  min-height: 48px;
  line-height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0 36px;
  cursor: pointer; }
  .in-progress-list-container .inv-row.selected-inv-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    background-color: white;
    font-weight: 500; }

.in-progress-list-container .inv-icon {
  flex: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .in-progress-list-container .inv-icon img {
    max-width: 100%; }

.in-progress-list-container .inv-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.in-progress-list-container .inv-product {
  flex: 3; }

.in-progress-list-container .inv-category {
  flex: 3; }

.in-progress-list-container .inv-in-stock {
  flex: 4; }

.in-progress-list-container .inv-can-make {
  flex: 4; }

.in-progress-list-container .inv-warning {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .in-progress-list-container .inv-warning img {
    height: 20px;
    width: 20px; }
