/* COLORS */
/* ---------------------------- */
.input-peripherals {
  display: flex;
  align-items: center;
  margin-left: 8px;
  min-width: 40px;
  color: #7AD69B; }

.input-peripherals-error {
  color: #dd5151;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer; }
  .input-peripherals-error span {
    border-bottom: 1px dashed #dd5151;
    vertical-align: middle; }
  .input-peripherals-error i {
    font-size: 17px;
    line-height: 17px;
    vertical-align: middle;
    border-bottom: none; }
