/* COLORS */
/* ---------------------------- */
.trends-line-chart {
  position: relative;
  overflow: visible; }
  .trends-line-chart .axis path,
  .trends-line-chart .axis line {
    fill: none;
    stroke: #cad0d5;
    shape-rendering: crispEdges; }
  .trends-line-chart .axis .tick line {
    opacity: 0; }
  .trends-line-chart .axis text {
    margin-top: 4px;
    fill: rgba(0, 0, 0, 0.48); }
  .trends-line-chart .line {
    fill: none;
    stroke: #0073f9;
    stroke-width: 1.5px; }
  .trends-line-chart .goal-line {
    stroke: #7AD69B;
    stroke-width: 1.5px;
    stroke-dasharray: 4 4; }
  .trends-line-chart .goal-label rect {
    fill: #7AD69B; }
  .trends-line-chart .goal-label text {
    fill: white;
    font-size: 12px; }
  .trends-line-chart .y-axis-label {
    font-size: 12px; }
  .trends-line-chart .overlay {
    fill: none;
    pointer-events: all; }
  .trends-line-chart .data-circle {
    stroke: #0073f9;
    stroke-width: 2px;
    fill: #f6f8f9; }
  .trends-line-chart .grid line {
    stroke: #cad0d5;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges; }
  .trends-line-chart .grid path {
    stroke-width: 0; }
  .trends-line-chart .hover-line {
    stroke: rgba(0, 0, 0, 0.88);
    stroke-width: 2px; }
  .trends-line-chart .hover-circle {
    stroke: rgba(0, 0, 0, 0.88); }
