/* COLORS */
/* ---------------------------- */
.goal {
  display: flex;
  padding: 16px;
  padding-left: 0px; }
  .goal span.more {
    font-size: 10px;
    color: #0073f9; }
  .goal .goal-icon {
    padding-right: 8px; }
    .goal .goal-icon img {
      height: 20px; }
  .goal .goal-info {
    flex: 6; }
    .goal .goal-info .goal-name {
      flex: 1;
      line-height: 20px;
      cursor: pointer; }
      .goal .goal-info .goal-name span.product {
        display: inline-block;
        font-size: 14px; }
    .goal .goal-info .goal-bars-wrapper {
      display: flex;
      flex-direction: column; }
      .goal .goal-info .goal-bars-wrapper .pinned-goal-zero-state {
        font-size: 12px;
        color: #a7b6c0; }
