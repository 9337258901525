/* COLORS */
/* ---------------------------- */
.task-qr {
  width: 188px;
  padding-top: 36px; }
  .task-qr .title {
    color: #a7b6c0;
    text-align: center;
    margin-bottom: 20px; }
    .task-qr .title img {
      height: 10px;
      width: 10px;
      margin-right: 8px; }
  .task-qr .qr-code-container {
    margin-bottom: 28px;
    height: 188px;
    width: 188px;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.68) 0 2px 4px 0; }
    .task-qr .qr-code-container img {
      height: 100%;
      width: 100%; }
  .task-qr .delete-task button {
    width: 100%; }
