/* COLORS */
/* ---------------------------- */
.datepicker .menuContentWrapper {
  min-width: 550px; }

.datepicker .secondary {
  padding: 0;
  width: 100%; }

.datepicker .dates {
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  vertical-align: middle; }
  .datepicker .dates span, .datepicker .dates i {
    line-height: 30px;
    margin-left: 8px; }
  .datepicker .dates i {
    flex: 0;
    font-size: 20px; }
