/* COLORS */
/* ---------------------------- */
.product-recipe-list {
  padding: 24px 36px;
  width: 100%; }
  .product-recipe-list .product-recipe-list-header {
    display: flex;
    padding: 16px 16px 4px; }
    .product-recipe-list .product-recipe-list-header > span {
      flex: 1;
      font-weight: 700; }
