@import url(../../../styles/constants.css);
.recipe-create-header {
  display: flex; }
  .recipe-create-header > span {
    flex: 1; }
  .recipe-create-header > button {
    flex: 0;
    white-space: nowrap;
    margin-right: 32px; }

.add-ingredient-button {
  display: flex; }
  .add-ingredient-button i, .add-ingredient-button span {
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle; }

.add-ingredient-button-container {
  margin-bottom: 16px; }
