/* COLORS */
/* ---------------------------- */
.inventory-container {
  width: calc(100vw - 64px);
  height: fit-content; }
  .inventory-container .inventory-page-help-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 24px;
    margin-bottom: 76px;
    margin-top: -88px; }
  .inventory-container .inventory-page-help {
    background-color: #0073f9;
    margin-bottom: 24px;
    color: white;
    padding: 12px;
    border-radius: 8px;
    width: 400px;
    cursor: pointer; }
  .inventory-container .inventory-page-help-header {
    font-weight: bold;
    padding-bottom: 12px; }
  .inventory-container .inventory-page-help-link {
    font-weight: bold; }

.inventory-content {
  display: flex;
  height: calc(100% - 56px); }
  .inventory-content .pagination {
    padding: 0 36px; }
  .inventory-content .ant-select {
    width: 300px;
    display: inline-block;
    margin-right: 32px; }
  .inventory-content .ant-calendar-picker {
    margin-right: 32px; }
  .inventory-content .no-items {
    margin: 36px; }
  .inventory-content .inventory-list-container {
    flex: 11; }
  .inventory-content .inventory-drawer-container {
    width: 368px; }
