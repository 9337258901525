/* COLORS */
/* ---------------------------- */
.walkthrough-processes-and-products div.card {
  min-width: 700px;
  text-align: center; }

.walkthrough-processes-and-products .bold {
  font-weight: bold; }

.walkthrough-processes-and-products .core-parts {
  height: 75px;
  border: 1px solid #E6E9EC;
  padding: 15px 25px;
  margin-bottom: 25px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.48); }
  .walkthrough-processes-and-products .core-parts .bold {
    color: rgba(0, 0, 0, 0.88); }
  .walkthrough-processes-and-products .core-parts .see-examples {
    color: #0073f9;
    text-align: center;
    margin-top: 5px;
    font-size: 14px; }
