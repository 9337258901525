.edit-process-info .icon-name-abbreviation {
  display: flex; }
  .edit-process-info .icon-name-abbreviation .icon-group {
    flex: 1; }
  .edit-process-info .icon-name-abbreviation .abbreviation-group {
    flex: 2;
    margin-left: 16px; }
  .edit-process-info .icon-name-abbreviation .name-group {
    flex: 4;
    margin-left: 16px; }

.edit-process-info .output-section-container {
  margin-top: 44px;
  margin-bottom: 80px; }

.edit-process-info .output-section-header {
  margin-bottom: 8px; }

.edit-process-info .output-quantity {
  display: flex; }
  .edit-process-info .output-quantity .number {
    flex: 1;
    margin-right: 20px; }
  .edit-process-info .output-quantity .unit {
    flex: 2; }

.edit-process-info .button-wide {
  flex: 1;
  width: 100%; }
