/* COLORS */
/* ---------------------------- */
.form-group .form-field {
  margin: 8px 0 24px; }

.form-label {
  margin-bottom: 8px; }
  .form-label label {
    font-size: 14px;
    color: #a7b6c0;
    margin-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
