/* COLORS */
/* ---------------------------- */
.app-section-header {
  font-size: 20px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 36px 0 36px;
  background-color: #0073f9;
  -webkit-font-smoothing: antialiased; }
  .app-section-header .button {
    background-color: white;
    color: #0073f9;
    font-size: 15px; }

.header-button {
  background-color: white;
  color: #0073f9;
  font-size: 15px; }
