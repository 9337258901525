/* COLORS */
/* ---------------------------- */
div.select .Select-control {
  border: 1px solid rgba(0, 0, 0, 0.28); }

div.select .Select-placeholder {
  color: rgba(0, 0, 0, 0.48);
  padding-left: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

div.select div.Select.is-focused:not(.is-open) > div.Select-control {
  border-color: #0073f9;
  box-shadow: none; }

div.select .defaultSelectOptionStyle {
  margin-top: 10px;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.28); }
