/* COLORS */
/* ---------------------------- */
.create-product-dialog .create-product-error {
  color: #f9438e;
  line-height: 16px;
  display: block;
  margin-bottom: 12px; }

.create-product-dialog .create-product-input {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px; }
  .create-product-dialog .create-product-input label {
    flex: 0; }
  .create-product-dialog .create-product-input input {
    flex: 1; }

.create-product-dialog .create-product-description {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 12px; }
  .create-product-dialog .create-product-description textarea {
    border: 1px solid rgba(0, 0, 0, 0.28);
    border-radius: 4px;
    line-height: 16px;
    padding: 8px;
    outline: none;
    resize: none; }
    .create-product-dialog .create-product-description textarea:focus {
      border-color: #0073f9; }
