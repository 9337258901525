/* COLORS */
/* ---------------------------- */
.goal-bar-wrapper {
  display: flex;
  position: relative; }
  .goal-bar-wrapper .goal-interval {
    flex: 3;
    min-width: 48px;
    font-size: 12px;
    color: #a7b6c0; }
  .goal-bar-wrapper:hover, .goal-bar-wrapper:hover .goal-interval {
    color: #0073f9;
    cursor: pointer; }
  .goal-bar-wrapper .goal-whole-bar {
    flex: 12;
    display: flex;
    height: 6px;
    margin-top: 8px;
    margin-bottom: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-align: right; }
  .goal-bar-wrapper .goal-filled-bar {
    background-color: #0073f9;
    background: linear-gradient(to left, #40b3ff, #0073f9);
    color: rgba(255, 255, 255, 0.5);
    border-radius: 4px 0 0 4px; }

.ant-tooltip-inner .goal-hover-text {
  font-size: 14px;
  color: #cad0d5; }
  .ant-tooltip-inner .goal-hover-text .current-progress {
    color: #40b3ff; }
