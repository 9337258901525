/* COLORS */
/* ---------------------------- */
div.walkthrough-create-user div.card {
  max-width: 875px;
  display: flex; }
  div.walkthrough-create-user div.card .walkthrough-input {
    margin-bottom: 32px; }
  div.walkthrough-create-user div.card > div {
    flex: 4; }
  div.walkthrough-create-user div.card > div:first-child {
    flex: 5; }

div.walkthrough-intro-info {
  background-color: #F6F8F9;
  border-right: 1px solid #DBE0E4;
  padding: 40px; }
  div.walkthrough-intro-info > div {
    margin-bottom: 36px; }
  div.walkthrough-intro-info h3 {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    font-size: 17px;
    text-transform: none;
    line-height: 20px;
    margin-bottom: 4px;
    letter-spacing: 0; }
  div.walkthrough-intro-info span {
    color: rgba(0, 0, 0, 0.35);
    font-size: 14px;
    line-height: 20px; }

.walkthrough-create-team .walkthrough-input {
  margin-bottom: 20px; }
