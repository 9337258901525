/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* COLORS */
/* ---------------------------- */
@font-face {
  font-family: "SF Pro Text";
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscotext-regular-webfont.woff"); }

@font-face {
  font-family: "Apercu";
  src: url("https://s3-us-west-1.amazonaws.com/polymer-fonts/Apercu.woff") format("woff"); }

@font-face {
  font-family: "Apercu";
  font-weight: 700;
  src: url("https://s3-us-west-1.amazonaws.com/polymer-fonts/Apercu-Bold.woff") format("woff"); }

@font-face {
  font-family: "Apercu Mono";
  font-weight: 700;
  src: url("https://s3-us-west-1.amazonaws.com/polymer-fonts/apercu-mono-regular.woff") format("woff");
  src: url("https://s3-us-west-1.amazonaws.com/polymer-fonts/apercu-mono-regular.woff2") format("woff2"); }

html, body, #root {
  width: 100%;
  height: 100%; }

.layout {
  display: flex;
  flex-direction: row;
  height: 100%; }

.baseline-grid {
  background-size: 16px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  z-index: 1; }

.application-content {
  padding-top: 64px;
  padding-left: 64px;
  height: 100%;
  display: flex;
  flex-grow: 1;
  background-color: rgba(246, 248, 249, 0.5);
  overflow-y: scroll; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Apercu", "Interface", "Helvetica", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #4f4f4f;
  padding: 0;
  margin: 0; }

div, input, * {
  box-sizing: border-box !important; }

span {
  line-height: 20px; }

.new-features-card {
  min-width: 500px;
  max-width: 800px; }
