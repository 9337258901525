/* COLORS */
/* ---------------------------- */
.production-aside-container {
  flex: 1;
  background-color: white;
  padding: 30px 20px 200px 20px; }
  .production-aside-container .title {
    font-weight: bold;
    margin-bottom: 20px; }
  .production-aside-container .empty-screen-text {
    margin: 20px 0;
    color: rgba(0, 0, 0, 0.48);
    font-style: italic; }
  .production-aside-container .link {
    color: #0073f9; }
  .production-aside-container .aside-item-container {
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.12);
    margin-bottom: 12px;
    cursor: pointer; }
    .production-aside-container .aside-item-container .outer-progress-bar {
      background-color: rgba(0, 0, 0, 0.08); }
      .production-aside-container .aside-item-container .outer-progress-bar .inner-progress-bar {
        background-color: #0073f9;
        height: 6px; }
    .production-aside-container .aside-item-container .content-container {
      padding: 8px 20px 20px 20px; }
      .production-aside-container .aside-item-container .content-container .title-container {
        display: flex;
        flex-direction: row; }
        .production-aside-container .aside-item-container .content-container .title-container .title {
          flex: 3;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.88);
          margin: 6px 0 10px 0; }
        .production-aside-container .aside-item-container .content-container .title-container .warning {
          flex: 1;
          text-align: right; }
      .production-aside-container .aside-item-container .content-container .info-container {
        display: flex;
        flex-direction: row; }
        .production-aside-container .aside-item-container .content-container .info-container .label {
          flex: 1;
          color: rgba(0, 0, 0, 0.48); }
        .production-aside-container .aside-item-container .content-container .info-container .value {
          flex: 1;
          text-align: right; }
  .production-aside-container .selected {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transform: scale(1.01); }
