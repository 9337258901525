/* COLORS */
/* ---------------------------- */
.incoming-deliveries .del-row {
  display: flex;
  min-height: 48px;
  line-height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0 36px;
  cursor: pointer; }
  .incoming-deliveries .del-row.selected-del-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    background-color: white;
    font-weight: 500; }

.incoming-deliveries .del-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.incoming-deliveries .del-icon {
  flex: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .incoming-deliveries .del-icon img {
    max-width: 100%; }

.incoming-deliveries .del-task {
  flex: 3; }

.incoming-deliveries .del-amount {
  flex: 2; }

.incoming-deliveries .del-sent-by {
  flex: 2; }

.incoming-deliveries .del-sent-at {
  flex: 3; }

.incoming-deliveries .del-action {
  flex: 1;
  display: flex;
  align-items: center; }
  .incoming-deliveries .del-action .del-accept {
    margin-left: 12px;
    margin-right: 12px; }

.incoming-deliveries .empty-screen-text {
  margin: 16px 0 8px 0;
  color: rgba(0, 0, 0, 0.48);
  font-style: italic; }
