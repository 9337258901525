/* COLORS */
/* ---------------------------- */
div.button-dropdown {
  position: relative;
  z-index: 1; }
  div.button-dropdown .button-dropdown-shim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    display: none; }
  div.button-dropdown .button-dropdown-content {
    position: absolute;
    top: 28px;
    right: 0px;
    z-index: 2;
    transform: scale(0, 0);
    transform-origin: right top;
    transition: 0.2s; }
    div.button-dropdown .button-dropdown-content > div {
      border-radius: 8px 0px 8px 8px; }
  div.button-dropdown.left-align .button-dropdown-content {
    right: inherit;
    transform-origin: left top; }
  div.button-dropdown.expanded {
    z-index: 999; }
    div.button-dropdown.expanded > button {
      background-color: #0073f9; }
      div.button-dropdown.expanded > button.secondary {
        background-color: rgba(0, 0, 0, 0); }
    div.button-dropdown.expanded .button-dropdown-shim {
      display: block; }
    div.button-dropdown.expanded .button-dropdown-content {
      display: block;
      transform: scale(1, 1); }
