/* COLORS */
/* ---------------------------- */
.line-chart-tooltip {
  z-index: 902;
  position: absolute;
  background-color: #1f2429;
  color: #f6f8f9;
  border-radius: 2px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 12px;
  -webkit-font-smoothing: antialiased; }
  .line-chart-tooltip .title {
    font-weight: bold; }
  .line-chart-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: #1f2429 transparent transparent transparent; }
