/* COLORS */
/* ---------------------------- */
.settings-page {
  width: calc(100vw - 64px);
  height: fit-content;
  flex: 1;
  padding-bottom: 200px; }
  .settings-page .settings-content {
    margin: 16px 16px 0 16px;
    max-width: 900px; }

.account-header h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 40px; }

.account-sectionheader h3 {
  text-transform: none;
  color: rgba(0, 0, 0, 0.48); }

.account-basics, .account-integrations, .account-team, .factory-options {
  margin-bottom: 50px; }

.account-basics-row {
  display: flex;
  padding: 4px 0; }
  .account-basics-row div {
    flex: 3; }
    .account-basics-row div:first-child {
      flex: 2;
      color: rgba(0, 0, 0, 0.48); }
    .account-basics-row div:last-child {
      flex: 1; }
    .account-basics-row div i {
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      color: #0073f9; }

.account-integration {
  -webkit-font-smoothing: antialiased; }
  .account-integration .integration-info {
    display: flex;
    margin-bottom: 6px;
    align-items: baseline; }
    .account-integration .integration-info div:first-child {
      flex: 1;
      flex-grow: 1;
      font-weight: 700; }
    .account-integration .integration-info div.integration-account {
      flex: 0;
      background-color: #0073f9;
      display: flex;
      color: white;
      border-radius: 12px;
      text-align: right;
      white-space: nowrap;
      align-items: center; }
      .account-integration .integration-info div.integration-account span {
        margin: 4px 0px 4px 8px; }
      .account-integration .integration-info div.integration-account .material-icons {
        font-size: 10px;
        line-height: 10px;
        padding: 1px;
        border: 1px solid white;
        border-radius: 10px;
        margin: 4px 6px;
        cursor: pointer; }
  .account-integration .integration-detail {
    color: #9A9A9A;
    line-height: 20px; }

.selectable-label-images {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  overflow: auto; }
  .selectable-label-images .label-image {
    flex: 0;
    cursor: pointer;
    margin-right: 16px; }
    .selectable-label-images .label-image img {
      min-width: 135px;
      max-width: 135px;
      min-height: 90px;
      max-height: 90px;
      border-radius: 6%;
      border: 2px solid white; }
    .selectable-label-images .label-image.selected > img {
      border: 2px solid #0073f9; }

.google-connect, .add-new-member {
  cursor: pointer;
  color: #0073f9; }

.team-members {
  background-color: rgba(187, 187, 187, 0.09);
  -webkit-font-smoothing: antialiased;
  border-radius: 4px;
  padding: 16px;
  margin-top: 10px; }
  .team-members .member {
    margin-bottom: 16px;
    display: flex; }
    .team-members .member .account-circle {
      flex: 0; }
    .team-members .member .account-names {
      flex: 1; }
      .team-members .member .account-names .account-name-real {
        font-weight: 700; }
    .team-members .member .account-types span.btn {
      flex: 0;
      color: rgba(0, 0, 0, 0.3);
      border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
      text-align: right;
      vertical-align: bottom; }
      .team-members .member .account-types span.btn i {
        font-size: 14px;
        line-height: 16px;
        vertical-align: bottom;
        padding-left: 2px; }
    .team-members .member span {
      display: block; }
  .team-members .accounttype-menu {
    min-width: 240px; }
    .team-members .accounttype-menu .accounttype-rule {
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1); }
    .team-members .accounttype-menu .accounttype-option {
      display: flex;
      padding: 16px;
      padding-left: 16px;
      cursor: pointer; }
      .team-members .accounttype-menu .accounttype-option .check {
        min-width: 16px;
        margin-right: 4px; }
        .team-members .accounttype-menu .accounttype-option .check i {
          font-size: 16px;
          line-height: 16px; }
      .team-members .accounttype-menu .accounttype-option .title {
        font-weight: 700; }
      .team-members .accounttype-menu .accounttype-option .desc {
        color: rgba(0, 0, 0, 0.4); }
