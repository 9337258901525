/* COLORS */
/* ---------------------------- */
.menu .button-dropdown-content .card {
  padding: 0px; }

.menu-section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08); }
  .menu-section:last-child {
    border-bottom: none; }

.account-menu {
  min-width: 180px; }
