/* COLORS */
/* ---------------------------- */
.alerts .alert:last-child {
  margin-bottom: 0; }

.alert-container {
  padding-left: 32px;
  background-color: white;
  z-index: 999; }

.alert {
  color: rgba(0, 0, 0, 0.48);
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 24px 32px 20px 16px;
  position: relative; }
  .alert span {
    line-height: 20px; }
  .alert .new-alert {
    background-color: #0073f9;
    min-height: 6px;
    min-width: 6px;
    max-height: 6px;
    max-width: 6px;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    margin-top: 7px; }
  .alert .alert-icon {
    height: 16px;
    position: absolute;
    left: -8px;
    margin-top: 2px; }
  .alert:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.05); }
  .alert .alert-text {
    display: flex;
    margin-bottom: 4px; }
  .alert .alert-circle {
    font-size: 20px;
    margin-right: 8px;
    vertical-align: bottom;
    flex: 0; }
  .alert .alert-link {
    color: rgba(0, 0, 0, 0.52);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    font-size: 12px;
    line-height: 20px; }
  .alert .view-all {
    margin-top: 2px;
    font-size: 12px;
    color: #0073f9;
    cursor: pointer; }

.alert-detail {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.52);
  line-height: 20px;
  display: flex; }
  .alert-detail a {
    display: flex;
    align-items: center; }
  .alert-detail i {
    display: none;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.52);
    margin-left: 4px; }
  .alert-detail:hover i {
    display: inline-block; }

.alerts-empty {
  background-color: rgba(0, 0, 0, 0.28);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .alerts-empty img {
    filter: grayscale(100%);
    opacity: 0.7; }
  .alerts-empty span {
    color: rgba(0, 0, 0, 0.48);
    font-size: 18px;
    font-weight: 20px;
    margin-bottom: 20px; }
