/* COLORS */
/* ---------------------------- */
.delivery-history .del-row {
  display: flex;
  min-height: 48px;
  line-height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0 36px;
  cursor: pointer; }
  .delivery-history .del-row.selected-del-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    background-color: white;
    font-weight: 500; }

.delivery-history .del-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.delivery-history .del-icon {
  flex: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .delivery-history .del-icon img {
    max-width: 100%; }

.delivery-history .del-label {
  flex: 5; }

.delivery-history .del-amount {
  flex: 3; }

.delivery-history .del-sent-by {
  flex: 3; }

.delivery-history .del-sent-to {
  flex: 3; }

.delivery-history .del-sent-at {
  flex: 3; }

.delivery-history .del-received-at {
  flex: 3; }

.delivery-history .del-action {
  flex: 3; }

.delivery-history .empty-screen-text {
  margin: 16px 36px 8px 36px;
  color: rgba(0, 0, 0, 0.48);
  font-style: italic; }

.delivery-history .object-list-header {
  border-top: none;
  padding: 0 36px 0 36px;
  margin: 0; }

.delivery-history .object-list-item {
  padding: 0 36px 0 36px; }
