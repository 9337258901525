.task-inputs-and-outputs {
  margin-top: 36px; }
  .task-inputs-and-outputs .task-attribute-table {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin-bottom: 10px; }
    .task-inputs-and-outputs .task-attribute-table .task-attribute-table-row {
      cursor: pointer;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px;
      display: flex;
      flex-direction: row;
      font-size: 12px; }
      .task-inputs-and-outputs .task-attribute-table .task-attribute-table-row i {
        font-size: 12px;
        line-height: 12px;
        vertical-align: middle;
        padding-right: 4px; }
      .task-inputs-and-outputs .task-attribute-table .task-attribute-table-row span {
        flex: 1;
        display: inline-block;
        line-height: 12px; }
        .task-inputs-and-outputs .task-attribute-table .task-attribute-table-row span:last-child {
          text-align: right; }
      .task-inputs-and-outputs .task-attribute-table .task-attribute-table-row:last-child {
        border-bottom: none; }
      .task-inputs-and-outputs .task-attribute-table .task-attribute-table-row.task-attribute-table-row-header {
        background-color: rgba(0, 0, 0, 0.05); }
      .task-inputs-and-outputs .task-attribute-table .task-attribute-table-row.zero-state {
        padding: 16px; }
        .task-inputs-and-outputs .task-attribute-table .task-attribute-table-row.zero-state span {
          text-align: center;
          color: rgba(0, 0, 0, 0.4); }
  .task-inputs-and-outputs div.task-attribute-table-row span.items-qr {
    text-transform: uppercase;
    flex: 4; }
    .task-inputs-and-outputs div.task-attribute-table-row span.items-qr button {
      cursor: pointer;
      border: none;
      outline: none;
      box-shadow: none;
      background-color: #0073F9;
      border-radius: 2px;
      color: white;
      font-size: 10px;
      letter-spacing: 1px;
      margin: 0px 4px;
      visibility: hidden; }
    .task-inputs-and-outputs div.task-attribute-table-row span.items-qr button.small-mark-button {
      background-color: rgba(0, 0, 0, 0.07);
      color: rgba(0, 0, 0, 0.3); }
  .task-inputs-and-outputs div.output-table-row:hover span.items-qr button {
    visibility: visible; }
  .task-inputs-and-outputs .expand-i {
    visibility: hidden;
    padding-left: 4px;
    color: #0073F9 !important; }
  .task-inputs-and-outputs .input-table-row:hover .expand-i {
    visibility: visible; }
  .task-inputs-and-outputs .inv-circle {
    height: 8px;
    width: 8px;
    border-radius: 12px;
    background-color: #A0DF88;
    margin-right: 4px;
    display: inline-block;
    vertical-align: baseline; }
  .task-inputs-and-outputs .input-count {
    color: rgba(0, 0, 0, 0.3); }
  .task-inputs-and-outputs .task-row-header img {
    height: 12px;
    margin-right: 4px; }
  .task-inputs-and-outputs .task_button {
    border: none;
    outline: none;
    background-color: white;
    color: #0073F9; }
