/* COLORS */
/* ---------------------------- */
.cumulative-area-chart {
  position: relative; }
  .cumulative-area-chart .axis path,
  .cumulative-area-chart .axis line {
    fill: none;
    stroke: #cad0d5;
    shape-rendering: crispEdges; }
  .cumulative-area-chart .axis text {
    fill: rgba(0, 0, 0, 0.48); }
  .cumulative-area-chart .area {
    stroke: #40b3ff;
    stroke-width: 1.5px;
    fill: #40b3ff;
    opacity: 0.8; }
  .cumulative-area-chart .goal-line {
    stroke: #7AD69B;
    stroke-width: 1.5px; }
  .cumulative-area-chart .goal-label rect {
    fill: #7AD69B; }
  .cumulative-area-chart .goal-label text {
    fill: white;
    font-size: 12px; }
  .cumulative-area-chart .y-axis-label {
    font-size: 12px; }
  .cumulative-area-chart .overlay {
    fill: none;
    pointer-events: all; }
  .cumulative-area-chart .data-circle {
    stroke: #40b3ff;
    fill: #40b3ff;
    stroke-width: 2px; }
  .cumulative-area-chart .grid line {
    stroke: #cad0d5;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges; }
  .cumulative-area-chart .grid path {
    stroke-width: 0; }
  .cumulative-area-chart .hover-line {
    stroke: rgba(0, 0, 0, 0.88);
    stroke-width: 2px; }
  .cumulative-area-chart .hover-circle {
    stroke: rgba(0, 0, 0, 0.88); }
