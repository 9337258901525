/* COLORS */
/* ---------------------------- */
.element-card-wrapper {
  display: flex; }
  .element-card-wrapper .process-attr-handle, .element-card-wrapper .process-attr-delete {
    flex: 0;
    display: flex;
    margin-top: 26px; }
    .element-card-wrapper .process-attr-handle i, .element-card-wrapper .process-attr-delete i {
      vertical-align: middle;
      font-size: 16px;
      color: #0073f9;
      visibility: hidden; }
    .element-card-wrapper .process-attr-handle span, .element-card-wrapper .process-attr-delete span {
      vertical-align: middle;
      width: 32px;
      max-width: 32px;
      overflow-x: hidden;
      color: rgba(0, 0, 0, 0.28); }
  .element-card-wrapper .element-card {
    flex: 1;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 0;
    margin: 8px 0;
    padding: 16px; }
  .element-card-wrapper .process-attr-handle > span {
    flex: 1; }
  .element-card-wrapper .process-attr-delete {
    margin-left: 16px;
    cursor: pointer; }

.element-card-wrapper.selected .element-card {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0; }

.element-card-wrapper.selected div.process-attr-handle i, .element-card-wrapper.selected div.process-attr-handle span {
  visibility: visible;
  color: rgba(0, 0, 0, 0.28); }

.element-card-wrapper.selected div.process-attr-delete i, .element-card-wrapper.selected div.process-attr-delete span {
  visibility: visible; }

/* HOVER STUFF */
.element-card-wrapper:hover .process-attr-handle i {
  visibility: visible; }

.element-card-wrapper:hover .process-attr-delete i {
  visibility: visible; }

.element-card-wrapper:hover .element-card {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0; }
