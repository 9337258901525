/* COLORS */
/* ---------------------------- */
.product-history {
  background-color: #f6f8f9;
  width: 260px;
  min-height: 100%;
  padding: 36px 12px 0; }
  .product-history .title {
    padding-left: 12px;
    color: #a7b6c0;
    margin-bottom: 20px; }
  .product-history .focus-box {
    padding: 16px 0;
    margin: 20px 0;
    background-color: white;
    width: 100%;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.08) 0 0 10px 0; }
  .product-history .inputs-label {
    display: flex;
    align-items: center;
    height: 16px;
    width: 80px;
    margin-left: 52px;
    margin-bottom: 12px;
    padding: 0 8px;
    background-color: #87ddff;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.68);
    font-size: 10px;
    text-transform: uppercase; }
    .product-history .inputs-label i {
      font-size: 10px;
      margin-right: 4px; }
  .product-history .task-summary {
    display: flex;
    margin-bottom: 12px;
    cursor: pointer;
    position: relative; }
    .product-history .task-summary .task-flagged, .product-history .task-summary .task-ancestor-flagged {
      font-size: 14px;
      line-height: 20px;
      vertical-align: middle;
      color: #dd5151;
      margin-right: 4px; }
    .product-history .task-summary .task-ancestor-flagged {
      color: #e99090; }
    .product-history .task-summary .task-flagged-name {
      color: #dd5151; }
    .product-history .task-summary .task-ancestor-flagged-name {
      color: #e99090; }
    .product-history .task-summary:last-child {
      margin-bottom: 0; }
    .product-history .task-summary .task-icon {
      width: 52px;
      flex-grow: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
    .product-history .task-summary .task-icon img {
      height: 20px;
      opacity: 0.5; }
    .product-history .task-summary .task-amount {
      font-size: 12px; }
    .product-history .task-summary.selected {
      color: black; }
      .product-history .task-summary.selected .task-icon img {
        opacity: 1; }
    .product-history .task-summary .expand-i {
      visibility: hidden;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 12px; }
    .product-history .task-summary:hover .expand-i {
      visibility: visible; }
