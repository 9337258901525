/* COLORS */
/* ---------------------------- */
.element-header i {
  opacity: 0.7;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 12px; }

.element-header span {
  vertical-align: middle; }

.element-content {
  display: flex;
  min-height: calc(100vh - 120px);
  height: calc(100vh - 64px);
  max-width: 1200px; }

.element-title {
  padding: 24px 0;
  display: flex;
  justify-content: space-between; }
  .element-title img {
    margin-right: 8px; }
  .element-title span {
    line-height: 30px;
    font-size: 20px; }
  .element-title .element-title-name {
    display: flex; }
