/* COLORS */
/* ---------------------------- */
.features {
  border-left: 4px solid #0073f9;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-font-smoothing: antialiased; }
  .features a {
    color: #0073f9; }

.nf {
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0;
  padding-top: 16px; }
  .nf:first-child {
    padding-top: 0; }

.nf-pill {
  display: inline-block;
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  color: white;
  margin-right: 4px; }
  .nf-pill.improvement {
    background-color: #5827d3; }
  .nf-pill.new-feature {
    background-color: #1ddbb1; }

.nf-title {
  font-weight: 700; }
