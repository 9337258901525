/* COLORS */
/* ---------------------------- */
.task-main {
  flex-grow: 1;
  padding: 36px 40px 40px; }
  .task-main .last-updated {
    color: #a7b6c0;
    margin-bottom: 20px; }
  .task-main .task-name {
    margin-bottom: 40px;
    color: black; }
    .task-main .task-name .name-row {
      display: flex;
      align-items: center; }
    .task-main .task-name img {
      height: 32px;
      margin-right: 8px; }
    .task-main .task-name .name {
      font-size: 20px;
      margin-right: 12px; }
    .task-main .task-name i {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.48);
      margin-right: 8px;
      vertical-align: middle; }
    .task-main .task-name .default-output {
      font-size: 16px; }
  .task-main .task-warnings {
    margin-bottom: 16px; }
    .task-main .task-warnings .warning-title {
      color: #a7b6c0;
      margin-bottom: 8px; }
    .task-main .task-warnings .input-container {
      position: relative;
      display: flex;
      max-height: 38px;
      margin-bottom: 16px; }
    .task-main .task-warnings .input-container input {
      max-width: 400px;
      min-width: 400px; }
      .task-main .task-warnings .input-container input.attr-error {
        border-color: #dd5151; }
  .task-main .task-info {
    width: 400px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap; }
    .task-main .task-info .group {
      width: 130px;
      margin-bottom: 40px;
      flex-shrink: 0; }
    .task-main .task-info .label {
      color: #a7b6c0;
      margin-bottom: 12px; }
    .task-main .task-info .value {
      color: rgba(0, 0, 0, 0.68); }
    .task-main .task-info .no-tags {
      color: rgba(0, 0, 0, 0.28); }
  .task-main .task-main-container > .card {
    margin-left: -4px;
    margin-bottom: 26px;
    min-width: 400px;
    max-width: 400px; }
  .task-main .task-cogs {
    display: flex;
    flex-direction: column; }
    .task-main .task-cogs .task-cogs-row {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .task-main .task-cogs .task-cogs-row div.info {
        display: flex;
        flex-direction: row;
        min-width: 300px;
        max-width: 300px;
        align-items: center;
        margin-bottom: 6px;
        margin-top: 6px; }
        .task-main .task-cogs .task-cogs-row div.info div {
          flex: 1; }
        .task-main .task-cogs .task-cogs-row div.info div.ant-input-number {
          margin-left: -20px; }
