/* COLORS */
/* ---------------------------- */
input.input {
  width: 100%;
  border: 1px solid #cad0d5;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.88);
  font-family: Apercu;
  font-size: 14px;
  padding: 8px;
  line-height: 20px;
  outline: none; }
  input.input:focus {
    border-color: #0073f9; }
  input.input::placeholder {
    color: #a7b6c0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
